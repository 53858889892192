import React from 'react';
import styled from 'styled-components';
import Checkbox, { CheckboxChangeEvent } from 'antd/es/checkbox';
import Divider from 'antd/es/divider';
import Modal from 'antd/es/modal';

interface Props {
    visible: boolean;
    disableOk: boolean;
    onOk: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    showCancel: boolean;
    okText?: string;
    allCheckboxesChecked?: boolean;
    onCancel?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    researchPrototypeCheckboxOnChange?: (e: CheckboxChangeEvent) => void;
    researchPrototypeConsented?: boolean;
    offensiveContentCheckboxOnChange?: (e: CheckboxChangeEvent) => void;
    offensiveContentConsented?: boolean;
    dataCollectionCheckboxOnChange?: (e: CheckboxChangeEvent) => void;
    dataCollectionConsented?: boolean;
}

const MODEL_VERSION = process.env.DELPHI_MODEL_VERSION;

export const ConsentModal = (props: Props) => {
    return (
        <ModalWithLeftAlignedFooter
            title={`Ask Delphi Terms & Conditions (${MODEL_VERSION})`}
            visible={props.visible}
            closable={false}
            onOk={props.onOk}
            cancelButtonProps={!props.showCancel ? { style: { display: 'none' } } : undefined}
            okButtonProps={{
                disabled: props.disableOk,
            }}
            onCancel={props.onCancel}
            okText={props.okText}
            width="1000px">
            <p>
                <i>
                    {' '}
                    <b>Please read the following information carefully before proceeding.</b>{' '}
                </i>
            </p>
            <p>
                Delphi is a <b> research prototype </b> designed to investigate the{' '}
                <b> promises </b> and more importantly, the <b> limitations </b> of modeling
                people’s moral judgments on a variety of everyday situations. The goal of Delphi is
                to help AI systems be more ethically-informed and equity-aware. By taking a step in
                this direction, we hope to inspire our research community to tackle the research
                challenges in this space head-on to build ethical, reliable, and inclusive AI
                systems.
            </p>
            <Checkbox
                checked={props.researchPrototypeConsented || props.allCheckboxesChecked}
                onChange={props.researchPrototypeCheckboxOnChange}>
                <b>
                    {' '}
                    <i>
                        {' '}
                        I understand that Ask Delphi is a research prototype and will be used only
                        for research purposes.{' '}
                    </i>{' '}
                </b>
            </Checkbox>
            <Divider />
            <SmallBottomSpaceParagraph>
                {' '}
                <b> What are the limitations of Delphi? </b> Large pretrained language models, such
                as GPT-3, are trained on mostly{' '}
                <a href="https://arxiv.org/abs/2104.08758" target="_blank" rel="noreferrer">
                    unfiltered internet data
                </a>
                , and therefore are extremely quick to produce{' '}
                <a
                    href="https://spectrum.ieee.org/open-ais-powerful-text-generating-tool-is-ready-for-business"
                    target="_blank"
                    rel="noreferrer">
                    toxic
                </a>
                ,{' '}
                <a href="https://arxiv.org/abs/2009.06807" target="_blank" rel="noreferrer">
                    unethical
                </a>
                , and{' '}
                <a href="https://aclanthology.org/D19-1339/" target="_blank" rel="noreferrer">
                    {' '}
                    harmful{' '}
                </a>{' '}
                content, especially about minority groups. Delphi’s responses are automatically
                extrapolated from a survey of US crowd workers, which helps reduce this issue but
                may introduce its own biases. Thus, some responses from Delphi may contain
                inappropriate or offensive results.{' '}
                <i> Please be mindful before sharing results. </i>
            </SmallBottomSpaceParagraph>
            <Checkbox
                checked={props.offensiveContentConsented || props.allCheckboxesChecked}
                onChange={props.offensiveContentCheckboxOnChange}>
                <b>
                    {' '}
                    <i>
                        {' '}
                        I understand that Ask Delphi may produce unintended inappropriate or
                        offensive results.{' '}
                    </i>{' '}
                </b>
            </Checkbox>
            <Divider />
            <SmallBottomSpaceParagraph>
                {' '}
                <b> Privacy and data collection </b> This website <i> DOES NOT </i> store any
                personal information of its users. It <i> DOES </i> store user queries for future
                research purposes.{' '}
            </SmallBottomSpaceParagraph>
            <Checkbox
                checked={props.dataCollectionConsented || props.allCheckboxesChecked}
                onChange={props.dataCollectionCheckboxOnChange}>
                <b>
                    {' '}
                    <i>
                        {' '}
                        I understand that my queries will be stored for future research purposes.{' '}
                    </i>{' '}
                </b>
            </Checkbox>
        </ModalWithLeftAlignedFooter>
    );
};

const SmallBottomSpaceParagraph = styled.p`
    margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const ModalWithLeftAlignedFooter = styled(Modal)`
    .ant-modal-footer {
        text-align: left;
    }
`;
