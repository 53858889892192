/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import { Header, Content, Footer, Layout } from '@allenai/varnish/components';
import { Link } from '@allenai/varnish-react-router';
import { FacebookFilled, TwitterSquareFilled, LinkedinFilled } from '@ant-design/icons';

import { Home, FAQ, Updates } from './pages';
import { ConsentModal } from './components';
import { AppRoute } from './AppRoute';
import headerLogo from './images/wo.svg';

export interface PageProps {
    consented: boolean;
}

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Home',
        component: Home,
    },
    {
        path: '/faq',
        label: 'FAQ',
        component: FAQ,
    },
    {
        path: '/updates',
        label: 'Updates',
        component: Updates,
    },
];

const MODEL_VERSION = process.env.DELPHI_MODEL_VERSION;
export const App = () => {
    const researchPrototypeConsentedKey = 'researchPrototypeConsented' + MODEL_VERSION;
    const offensiveContentConsentedKey = 'offensiveContentConsented' + MODEL_VERSION;
    const dataCollectionConsentedKey = 'dataCollectionConsented' + MODEL_VERSION;

    const [researchPrototypeConsented, setResearchPrototypeConsented] = useState(
        localStorage.getItem(researchPrototypeConsentedKey) !== null
    );
    const [offensiveContentConsented, setOffensiveContentConsented] = useState(
        localStorage.getItem(offensiveContentConsentedKey) !== null
    );
    const [dataCollectionConsented, setDataCollectionConsented] = useState(
        localStorage.getItem(dataCollectionConsentedKey) !== null
    );
    const [showModal, setShowModal] = useState(
        !offensiveContentConsented || !dataCollectionConsented || !researchPrototypeConsented
    );

    const handleModalOK = () => {
        localStorage.setItem(researchPrototypeConsentedKey, 'true');
        localStorage.setItem(dataCollectionConsentedKey, 'true');
        localStorage.setItem(offensiveContentConsentedKey, 'true');
        setResearchPrototypeConsented(true);
        setOffensiveContentConsented(true);
        setDataCollectionConsented(true);
        setShowModal(false);
    };

    return (
        <BrowserRouter>
            <Route path="/">
                <Layout bgcolor="white">
                    <Header>
                        <Header.Columns columns="auto 1fr">
                            <UnstyledLink to="/">
                                <Header.Logo label={<Header.AppName>Ask Delphi</Header.AppName>}>
                                    <Logo />
                                </Header.Logo>
                            </UnstyledLink>
                            <ShareArea>
                                <a
                                    href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdelphi.allenai.org"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <FacebookFilled alt="Share on Facebook" />
                                </a>
                                <a
                                    href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdelphi.allenai.org&text=Delphi%20is%20a%20computational%20model%20for%20descriptive%20ethics%2C%20i.e.%2C%20people%E2%80%99s%20moral%20judgments%20on%20a%20variety%20of%20everyday%20situations.%20Come%20to%20test%20Delphi%21"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <TwitterSquareFilled alt="Share on Twitter" />
                                </a>
                                <a
                                    href="http://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fdelphi.allenai.org&title=Ask%20Delphi&summary=Delphi%20is%20a%20computational%20model%20for%20descriptive%20ethics%2C%20i.e.%2C%20people%E2%80%99s%20moral%20judgments%20on%20a%20variety%20of%20everyday%20situations.%20Come%20to%20test%20Delphi%21"
                                    target="_blank"
                                    rel="noopener noreferrer">
                                    <LinkedinFilled alt="Share on Linkedin" />
                                </a>
                            </ShareArea>
                        </Header.Columns>
                    </Header>
                    <MaxWidthContent main>
                        {ROUTES.map(({ path, component }) => (
                            <Route
                                key={path}
                                path={path}
                                exact
                                render={() =>
                                    React.createElement(component, { consented: !showModal })
                                }
                            />
                        ))}
                    </MaxWidthContent>
                    <Footer />
                    <ConsentModal
                        visible={showModal}
                        disableOk={
                            !dataCollectionConsented ||
                            !offensiveContentConsented ||
                            !researchPrototypeConsented
                        }
                        showCancel={false}
                        onOk={handleModalOK}
                        researchPrototypeConsented={researchPrototypeConsented}
                        researchPrototypeCheckboxOnChange={() =>
                            setResearchPrototypeConsented(!researchPrototypeConsented)
                        }
                        offensiveContentConsented={offensiveContentConsented}
                        offensiveContentCheckboxOnChange={() =>
                            setOffensiveContentConsented(!offensiveContentConsented)
                        }
                        dataCollectionConsented={dataCollectionConsented}
                        dataCollectionCheckboxOnChange={() =>
                            setDataCollectionConsented(!dataCollectionConsented)
                        }
                        okText={'I agree'}
                    />
                </Layout>
            </Route>
        </BrowserRouter>
    );
};

const ShareArea = styled.div`
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: ${({ theme }) => theme.spacing.xs};
    align-items: center;
    justify-self: end;
    a {
        font-size: ${({ theme }) => theme.typography.h2.fontSize};
        color: ${({ theme }) => theme.color.N6};
    }
`;

const MaxWidthContent = styled(Content)`
    max-width: ${({ theme }) => theme.breakpoints.xl};
`;

const Logo = styled.img.attrs({
    src: headerLogo,
    alt: 'Ask Delphi Logo',
    height: '54px',
})`
    margin-right: ${({ theme }) => theme.spacing.sm};
`;

const UnstyledLink = styled(Link)`
    &&& {
        :hover {
            text-decoration: none;
        }
    }
`;
