export enum AgreementRating {
    AGREE,
    DISAGREE,
    DONT_KNOW,
}

const AgreementRatingToString = (rating: AgreementRating | undefined): string | null => {
    if (rating === AgreementRating.AGREE) {
        return 'agree';
    } else if (rating === AgreementRating.DISAGREE) {
        return 'disagree';
    } else if (rating === AgreementRating.DONT_KNOW) {
        return "i don't know";
    } else {
        return null;
    }
};

/**
 * Representation of feedback, which contains an agreement rating and open-text feedback.
 */
export class Feedback {
    constructor(readonly agreement?: AgreementRating, readonly textFeedback?: string) {}

    /**
     * Returns whether the current query has any populated values.
     *
     * @return {boolean}
     */
    isValid() {
        return this.agreement || this.textFeedback;
    }

    /**
     * Test equality of the query.
     *
     * @param {Query} other
     *
     * @returns {boolean}
     */
    equals(other: Feedback): boolean {
        return this.agreement === other.agreement && this.textFeedback === other.textFeedback;
    }

    static FeedbackToPayload(feedback: Feedback | undefined) {
        if (!feedback) {
            return null;
        } else {
            return {
                agreement: AgreementRatingToString(feedback.agreement),
                textFeedback: feedback.textFeedback ? feedback.textFeedback : null,
            };
        }
    }
}
