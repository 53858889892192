import React from 'react';
import styled, { css } from 'styled-components';
import Alert from 'antd/es/alert';
import Tooltip from 'antd/es/tooltip';
import { textStyles } from '@allenai/varnish/components';
import { TwitterOutlined, WarningOutlined } from '@ant-design/icons';

import { Answer, Query } from '../api';

interface Props {
    answer: Answer;
    isPrevious?: boolean;
}

export const DelphiCard = ({ answer, isPrevious }: Props) => {
    const isToxic = answer.answer.toxicityScore !== undefined && answer.answer.toxicityScore > 0.65;
    return (
        <SpacedAlert
            isPrevious={!!isPrevious}
            isToxic={isToxic}
            type="info"
            message={
                <div>
                    <DelphiGuessParagraph>
                        {isPrevious ? (
                            <Tooltip title="In Greek mythology, Delphi is an oracle not to be trusted.">
                                {' '}
                                <span>Previously, Delphi speculated:</span>{' '}
                            </Tooltip>
                        ) : (
                            <Tooltip title="In Greek mythology, Delphi is an oracle not to be trusted.">
                                {' '}
                                <span>Delphi speculates:</span>{' '}
                            </Tooltip>
                        )}
                    </DelphiGuessParagraph>
                    {isToxic ? (
                        <ToxicDisclaimerParagraph>
                            This statement may contain unintended offensive content. Reader
                            discretion is strongly advised. Please be mindful before sharing.
                        </ToxicDisclaimerParagraph>
                    ) : (
                        <DisclaimerParagraph>
                            Delphi’s responses are automatically extrapolated from a survey of US
                            crowd workers and may contain inappropriate or offensive results.
                        </DisclaimerParagraph>
                    )}
                </div>
            }
            description={
                <>
                    <Tooltip title={JSON.stringify(answer.answer)}>{getAlertText(answer)}</Tooltip>
                    {!isPrevious && !isToxic ? <LargeTweetBtn answer={answer} /> : null}
                    {isToxic ? <LargeWarning /> : null}
                </>
            }
        />
    );
};

const getAlertText = (answer: Answer) => {
    let ColorComp = Neutral;
    switch (answer.answer.class) {
        case -1:
            ColorComp = Bad;
            break;
        case 1:
            ColorComp = Good;
            break;
    }
    return (
        <Quote>
            <q>{answer.query.action1}</q>
            <div>
                {' - '}
                <StongItalic>
                    <ColorComp>{answer.answer.text}</ColorComp>
                </StongItalic>
                <DelphiVersion>{answer.answer.modelVersion}</DelphiVersion>
            </div>
        </Quote>
    );
};

const SpacedAlert = styled(Alert)<{ isPrevious: boolean; isToxic: boolean }>`
    &&& {
        padding: ${({ theme }) => theme.spacing.xl};
        border: 0;
        border-radius: 0;
        border-left: solid ${({ theme }) => theme.color.B6} ${({ theme }) => theme.spacing.xs};

        margin-top: ${({ theme, isPrevious }) => (isPrevious ? null : theme.spacing.xl)};
        height: ${({ isPrevious }) => (isPrevious ? '100%' : null)};
        border-color: ${({ theme, isPrevious, isToxic }) =>
            isPrevious ? theme.color.N6 : isToxic ? theme.color.R6 : theme.color.B6};
        background-color: ${({ theme, isToxic }) =>
            isToxic ? theme.palette.background.error : theme.palette.background.info};

        .ant-alert-message {
            margin-bottom: ${({ theme }) => theme.spacing.sm};
            font-size: ${({ theme }) => theme.typography.textStyles.jumbo.fontSize};
        }
    }
`;

const DisclaimerParagraph = styled.p`
    &&& {
        font-size: 0.5em;
        margin-bottom: ${({ theme }) => theme.spacing.sm};
        margin-left: ${({ theme }) => theme.spacing.sm};
        color: ${({ theme }) => theme.color.N7};
        font-style: italic;
    }
`;

const ToxicDisclaimerParagraph = styled(DisclaimerParagraph)`
    &&& {
        color: ${({ theme }) => theme.color.O9};
        font-weight: bold;
        font-style: normal;
    }
`;

const DelphiGuessParagraph = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.xs};
    }
`;

const StongItalic = styled.span`
    font-weight: ${({ theme }) => theme.typography.font.weight.bold};
    font-style: italic;
    font-stretch: expanded;
`;

const Bad = styled.span`
    color: ${({ theme }) => theme.color.R7};
`;

const Neutral = styled.span`
    color: ${({ theme }) => theme.color.N9};
`;

const Good = styled.span`
    color: ${({ theme }) => theme.color.B6};
`;

const Quote = styled(textStyles.Quote)`
    line-height: 1.25;
`;

const getTweetText = (answer: Answer) => {
    const ret = `https://twitter.com/intent/tweet?url=https%3A%2F%2Fdelphi.allenai.org/?${encodeURIComponent(
        new Query(answer.query.action1).toQueryString()
    )}&text=`;
    return (
        ret +
        `Ask Delphi:%0a${answer.query.action1}%0aDelphi speculates:%0a“${answer.answer.text}”%0a`
    );
};

const CornerIcon = css`
    && {
        position: absolute;
        right: ${({ theme }) => theme.typography.h4.fontSize};
        top: ${({ theme }) => theme.typography.h4.fontSize};
        margin-left: ${({ theme }) => theme.spacing.sm};
        font-size: ${({ theme }) => theme.typography.h4.fontSize};
        color: ${({ theme }) => theme.color.N6};
    }
`;

const TweetBtn = ({ answer, className }: { answer: Answer; className?: string }) => (
    <Tooltip title="Share this on Twitter">
        <a
            className={className}
            href={getTweetText(answer)}
            target="_blank"
            rel="noopener noreferrer">
            <TwitterOutlined alt="Share on Twitter" />
        </a>
    </Tooltip>
);

const LargeTweetBtn = styled(TweetBtn)`
    ${CornerIcon};
`;

const Warning = ({ className }: { className?: string }) => (
    <WarningOutlined className={className} />
);

const LargeWarning = styled(Warning)`
    ${CornerIcon};
    && {
        color: ${({ theme }) => theme.color.O8};
    }
`;

const DelphiVersion = styled(textStyles.Normal)`
    position: absolute;
    right: ${({ theme }) => theme.spacing.xl};
    bottom: ${({ theme }) => theme.spacing.xl};
    font-size: 14px;
    color: ${({ theme }) => theme.color.N7};
`;
