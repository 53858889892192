import React from 'react';

import styled from 'styled-components';
import { Link } from '@allenai/varnish-react-router';

import { MosaicFooter, NoConsentAlert } from '../components';
import { PageProps } from '../App';

export const FAQ = (props: PageProps) => {
    return (
        <React.Fragment>
            <div>
                <h3> Frequently Asked Questions </h3>
                <p>
                    {' '}
                    We've received many questions from the community about our{' '}
                    <Link to="/">demo</Link> and responded to some of the most common ones. If you
                    have any other questions about this research, please contact{' '}
                    <a href="mailto:delphi@allenai.org">delphi@allenai.org</a>.
                </p>
                {props.consented ? (
                    <>
                        <Question> Q: What’s the goal of the research behind Delphi? </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> Extreme-scale neural networks learned from raw
                            internet data are ever more powerful than we anticipated, yet fail to
                            learn human values, norms, and ethics. Our research aims to address the
                            impending need to teach AI systems to be ethically-informed and
                            socially-aware. Delphi demonstrates both the promises and the
                            limitations of language-based neural models when taught with ethical
                            judgments made by people.{' '}
                        </Answer>
                        <Question> Q: What is this system able to reason about? </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> Delphi is an AI system that guesses how an “average”
                            American person might judge the ethicality/social acceptability of a
                            given situation, based on the judgments obtained from a set of U.S.
                            crowdworkers for everyday situations. Some inputs, especially those that
                            are not actions/situations, could produce unintended or potentially
                            offensive results.{' '}
                        </Answer>
                        <Question>
                            {' '}
                            Q: How robust is Delphi against race- and gender-related statements?{' '}
                        </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> Delphi 1.0.4 demonstrates 97.9% accuracy on
                            race-related and 99.3% on gender-related statements. After its initial
                            launch, we enhanced Delphi 1.0.0’s guards against statements about
                            racism and sexism, which used to show 91.2% and 97.3% accuracy. Even
                            with these improvements, we will strive to continuously improve the
                            fairness and justice of the model to push the boundary of inclusive AI
                            systems.{' '}
                        </Answer>
                        <Question>
                            {' '}
                            Q: Is it true that Delphi is learning moral judgments from Reddit?{' '}
                        </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> No. Delphi is learning moral judgments from people who
                            are carefully qualified on MTurk. Only the situations used in questions
                            are harvested from Reddit, as it is a great source of ethically
                            questionable situations.{' '}
                        </Answer>
                        {/* <Question>
                            {' '}
                            Q: What Delphi said might be true today, but that doesn’t mean it should
                            be true in an ideal world...right?{' '}
                        </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> That’s correct, Delphi might be (kind of) right based
                            on today’s biased society/status quo, but today’s society is unequal and
                            biased. This is a common issue with AI systems, as many scholars have
                            argued, because AI systems are trained on historical or present data and
                            have no way of shaping the future of society, only humans can. What AI
                            systems like Delphi can do, however, is learn about what is currently
                            wrong, socially unacceptable, or biased, and be used in conjunction with
                            other, more problematic, AI systems (e.g., GPT-3) and help avoid that
                            problematic content.{' '}
                        </Answer> */}
                        <Question>
                            {' '}
                            Q: Does Delphi mostly reflect US-centric culture and moral values?{' '}
                        </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> Short answer: yes. Delphi is trained on Commonsense
                            Norm Bank, which contains judgments from American crowdsource workers
                            based on situations described in English. Likely it reflects what you
                            would think as “majority” groups in the US, i.e., white, heterosexual,
                            able-bodied, housed, etc. It is therefore not expected that it would
                            reflect any other set of social norms. However, it might still be able
                            to capture some cultural variation, surprisingly (see the paper for
                            examples). But much more work needs to be done to teach Delphi about
                            different cultures, from different countries to different subgroups
                            within the US.{' '}
                        </Answer>
                        <Question>
                            {' '}
                            Q: Why should we take actions to work on systems like Delphi?{' '}
                        </Question>
                        <Answer>
                            {' '}
                            <span> A: </span> Large language models (LMs) like the kind that powers
                            Delphi are very likely here to stay (e.g., Microsoft gaining exclusive
                            licenses to LMs like GPT-3 and future iterations). Our previous research
                            raised the point that these models, if used as is, can lead to highly
                            toxic and harmful interactions. As such, it becomes clear that the only
                            way to improve these models and mitigate their downsides is to invest in
                            the research that will make them more transparent, unbiased, and robust
                            on the social and ethical norms of the societies in which they operate.{' '}
                            <br /> <br />
                            To read more about our reflection on taking an action vs. inaction,
                            please read our new article:{' '}
                            <a
                                href="https://medium.com/ai2-blog/towards-machine-ethics-and-norms-d64f2bdde6a3"
                                target="_blank"
                                rel="noreferrer">
                                Towards Machine Ethics and Norms: making machines more inclusive,
                                ethically-informed, and socially-aware
                            </a>
                            .
                        </Answer>
                        <Question>
                            {' '}
                            Q: What are potential positive impacts of machine ethics research?{' '}
                        </Question>
                        <Answer>
                            {' '}
                            <span> A: </span>{' '}
                            <b>
                                {' '}
                                First and foremost,{' '}
                                <i>
                                    {' '}
                                    all AI systems are not, and should never, be used as moral
                                    authorities or sources of advice on ethics.{' '}
                                </i>{' '}
                            </b>{' '}
                            Our research is a step towards the grand goal of making AI more
                            explicitly{' '}
                            <b>
                                {' '}
                                inclusive, ethically informed, and socially aware when interacting
                                directly with humans.
                            </b>{' '}
                            This explicit teaching of ethics and social awareness{' '}
                            <a
                                href="https://www.sciencedirect.com/science/article/pii/S0004370216300868?via%3Dihub"
                                target="_blank"
                                rel="noreferrer">
                                is crucial
                            </a>
                            , especially because AI systems that directly interact with humans
                            already exist (
                            <a
                                href="https://www.theverge.com/2020/6/18/21296044/tiktok-for-you-page-algorithm-sides-engagement-data-creators-trends-sounds"
                                target="_blank"
                                rel="noreferrer">
                                modulating
                            </a>{' '}
                            <a
                                href="https://www.washingtonpost.com/technology/interactive/2021/how-facebook-algorithm-works/"
                                target="_blank"
                                rel="noreferrer">
                                social
                            </a>{' '}
                            <a
                                href="https://www.theverge.com/2021/10/22/22740703/twitter-algorithm-right-wing-amplification-study"
                                target="_blank"
                                rel="noreferrer">
                                media
                            </a>{' '}
                            <a
                                href="https://ai.facebook.com/blog/powered-by-ai-instagrams-explore-recommender-system/"
                                target="_blank"
                                rel="noreferrer">
                                feeds
                            </a>
                            ,{' '}
                            <a
                                href="https://www.grammarly.com/blog/grammarly-writing-assistant-for-professionals/?utm_source=google&utm_medium=cpc&utm_campaign=ProOutcomes&utm_content=471300623246&utm_term=writing%20an%20email&matchtype=b&placement=&network=g&gclid=Cj0KCQjww4OMBhCUARIsAILndv4bvceA5ZOabEJfniQGdzIblKM94pjICTzlUjYsfy3gbFBz4pEwclAaAuUAEALw_wcB&gclsrc=aw.ds"
                                target="_blank"
                                rel="noreferrer">
                                writing
                            </a>{' '}
                            <a
                                href="https://www.blog.google/products/gmail/subject-write-emails-faster-smart-compose-gmail/"
                                target="_blank"
                                rel="noreferrer">
                                emails
                            </a>
                            ,{' '}
                            <a
                                href="https://www.ibm.com/products/watson-assistant"
                                target="_blank"
                                rel="noreferrer">
                                conversational
                            </a>{' '}
                            <a
                                href="https://www.developer.amazon.com/en-US/alexa/"
                                target="_blank"
                                rel="noreferrer">
                                AI
                            </a>{' '}
                            <a
                                href="https://powervirtualagents.microsoft.com/en-us/"
                                target="_blank"
                                rel="noreferrer">
                                agents
                            </a>{' '}
                            <a
                                href="https://cloud.google.com/dialogflow"
                                target="_blank"
                                rel="noreferrer">
                                or
                            </a>{' '}
                            <a
                                href="https://www.facebook.com/business/help/321167622310680?id=648321075955172"
                                target="_blank"
                                rel="noreferrer">
                                chatbots
                            </a>
                            ,{' '}
                            <a
                                href="https://www.hrreporter.com/focus-areas/culture-and-engagement/ai-powered-tool-helps-with-recruitment/361298"
                                target="_blank"
                                rel="noreferrer">
                                applying for jobs
                            </a>
                            , etc.). Currently, such systems only <i>implicitly</i> encode moral
                            judgments and social dynamics from their training data, which makes them
                            easily susceptible to unethical behavior such as{' '}
                            <a
                                href="https://www.theverge.com/2016/3/24/11297050/tay-microsoft-chatbot-racist"
                                target="_blank"
                                rel="noreferrer">
                                toxicity
                            </a>
                            ,{' '}
                            <a
                                href="https://www.theguardian.com/technology/2021/oct/22/twitter-admits-bias-in-algorithm-for-rightwing-politicians-and-news-outlets"
                                target="_blank"
                                rel="noreferrer">
                                bias
                            </a>
                            , and{' '}
                            <a
                                href="https://venturebeat.com/2017/11/13/ais-stock-trading-potential-is-probably-overhyped/"
                                target="_blank"
                                rel="noreferrer">
                                other
                            </a>{' '}
                            <a
                                href="https://www.cbsnews.com/news/i-never-said-that-high-tech-deception-of-deepfake-videos/"
                                target="_blank"
                                rel="noreferrer">
                                harms
                            </a>{' '}
                            (as also shown in this and prior work). <br /> <br />
                            To read more about potential positive impacts of this line of research,
                            please read our new article:{' '}
                            <a
                                href="https://medium.com/ai2-blog/towards-machine-ethics-and-norms-d64f2bdde6a3"
                                target="_blank"
                                rel="noreferrer">
                                Towards Machine Ethics and Norms: making machines more inclusive,
                                ethically-informed, and socially-aware
                            </a>
                            .
                        </Answer>
                    </>
                ) : (
                    <NoConsentAlert />
                )}
            </div>
            <Bottom>
                <MosaicFooter />
            </Bottom>
        </React.Fragment>
    );
};

const Bottom = styled.div`
    grid-area: bottom;
    margin-top: ${({ theme }) => theme.spacing.xl};

    h5 {
        text-transform: none;
    }
`;

const Question = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.xxs};
        margin-left: ${({ theme }) => theme.spacing.sm};
        font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        color: ${({ theme }) => theme.color.O8};
        font-size: 1.25em;
    }
`;

const Answer = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.lg};
        margin-left: ${({ theme }) => theme.spacing.sm};

        span {
            font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        }
    }
`;
