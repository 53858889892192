import React, { useState } from 'react';
import Button from 'antd/es/button';
import { Link } from '@allenai/varnish-react-router';

import { ConsentModal, StyledHashLink } from '../components';

interface Update {
    longDesc: (...props: any) => JSX.Element;
    shortDesc: (...props: any) => JSX.Element;
    date: Date;
    title: string;
    id: string;
}

export const updates: Update[] = [
    {
        shortDesc: () => (
            <React.Fragment>
                <StyledHashLink to={'/updates#article'}>Our new article</StyledHashLink> on the
                topic of{' '}
                <a
                    href="https://medium.com/ai2-blog/towards-machine-ethics-and-norms-d64f2bdde6a3"
                    target="_blank"
                    rel="noreferrer">
                    Towards Machine Ethics and Norms: making machines more inclusive,
                    ethically-informed, and socially-aware
                </a>
                .
            </React.Fragment>
        ),
        longDesc: () => {
            return (
                <React.Fragment>
                    New article about{' '}
                    <a
                        href="https://medium.com/ai2-blog/towards-machine-ethics-and-norms-d64f2bdde6a3"
                        target="_blank"
                        rel="noreferrer">
                        Towards Machine Ethics and Norms: making machines more inclusive,
                        ethically-informed, and socially-aware
                    </a>
                    . In this article, we would like to share what happened since the initial
                    release of the Delphi demo and paper on Oct 14. We will start with an overview
                    of Delphi as a step towards a nascent AI field of machine ethics, describe the
                    stress testing of the research prototype, apologize for the mistakes on how we
                    released the demo and share what lessons we have learned, and reflect on why
                    working on imperfect solutions to build ethically-aware and socially-responsible
                    machines is better than inaction.
                </React.Fragment>
            );
        },
        date: new Date(2021, 10, 4),
        title: 'Article on Delphi',
        id: 'article',
    },
    {
        shortDesc: () => (
            <React.Fragment>
                {' '}
                <StyledHashLink to={'/updates#terms_and_conditions'}>
                    Terms and conditions
                </StyledHashLink>{' '}
                updated.{' '}
            </React.Fragment>
        ),
        longDesc: (props: any) => {
            const [showModal, setShowModal] = useState(props.hash === '#terms_and_conditions');

            const closeModal = () => {
                setShowModal(false);
            };

            return (
                <React.Fragment>
                    <ConsentModal
                        visible={showModal}
                        disableOk={false}
                        onOk={closeModal}
                        allCheckboxesChecked={true}
                        showCancel={false}
                    />{' '}
                    Terms and conditions updated. Users now are required to read and understand the
                    purpose of the demo as a research prototype, potential risks of unintended
                    offensive content, and the data retention policy prior to proceeding to the demo
                    page. You can view the terms and conditions by clicking the button below. <br />
                    <br />{' '}
                    <Button type="primary" onClick={() => setShowModal(true)}>
                        {' '}
                        View terms and conditions{' '}
                    </Button>{' '}
                </React.Fragment>
            );
        },
        date: new Date(2021, 9, 29),
        title: 'Adding terms and conditions',
        id: 'terms_and_conditions',
    },
    {
        shortDesc: () => (
            <React.Fragment>
                {' '}
                <StyledHashLink to={'/updates#delphi_1.0.4'}>Delphi 1.0.4</StyledHashLink> is
                released with enhanced guards against statements implying racism and sexism.{' '}
            </React.Fragment>
        ),
        longDesc: () => (
            <React.Fragment>
                {' '}
                Delphi 1.0.4 is released with enhanced guards against statements implying racism and
                sexism. Delphi 1.0.4 is enhanced with a diverse set of 70K new annotations of
                adversarially collected data from user queries, emphasizing gender and racial equity
                examples. As a result, Delphi 1.0.4 demonstrates 97.9% accuracy on race-related and
                99.3% on gender-related statements, which is in contrast with Delphi 1.0.0, which
                used to show 91.2% and 97.3% accuracy.
                <br /> <br />
                We will keep reducing those numbers as we roll out new iterations of our model that
                are even better guarded against racism and sexism, and we welcome empirical
                investigations into Delphi’s biases and ethical issues.
            </React.Fragment>
        ),
        date: new Date(2021, 9, 27),
        title: 'Delphi 1.0.4 release',
        id: 'delphi_1.0.4',
    },
    {
        shortDesc: () => (
            <React.Fragment>
                {' '}
                <StyledHashLink to={'/updates#delphi_1.0.3'}>Delphi 1.0.3</StyledHashLink> is
                released with a new offensive speech detection feature.{' '}
            </React.Fragment>
        ),
        longDesc: () => (
            <React.Fragment>
                {' '}
                Delphi 1.0.3 is released with a new offensive speech detection feature to flag
                potentially toxic, offensive, or harmful user queries and model output. We adopted
                the offensive speech detection model from{' '}
                <a href="https://arxiv.org/abs/2010.12472" target="_blank" rel="noreferrer">
                    HateBert
                </a>{' '}
                (Caselii et al. 2021). We also disabled the sharing feature on flagged messages to
                mitigate the spreading of potentially harmful content.
                <br />
                <br />
                We added a <Link to="/faq">frequently asked questions (FAQ) page</Link> that
                clarifies our research questions and intents. It answers common questions the public
                has regarding our research prototype.
                <br />
                <br />
                Additionally, we added a more diverse set of example queries to guide users through
                the appropriate kind of tasks the model is designed for.
                <br />
                <br />
                Moreover, instead of always responding with a clean-cut judgment, Delphi 1.0.3
                responds with a hesitation when the query is confusing (e.g., “it’s unclear,” “it’s
                ambiguous”), and decline to answer potentially offensive or explicit queries (e.g.,
                “it’s offensive”, “it’s explicit”).
            </React.Fragment>
        ),
        date: new Date(2021, 9, 25),
        title: 'Delphi 1.0.3 release, adding offensive speech detection',
        id: 'delphi_1.0.3_and_offensive_speech_detection',
    },
    {
        shortDesc: () => (
            <React.Fragment>
                <StyledHashLink to={'/updates#delphi_1.0.1'}>Delphi 1.0.1</StyledHashLink> is
                released, including information that Delphi is a research prototype.
            </React.Fragment>
        ),
        longDesc: () => (
            <React.Fragment>
                {' '}
                Delphi 1.0.1 is released. We updated the demo description to stress that Delphi is a
                research prototype intended to demonstrate the promises and limitations of
                computational ethics and investigate the broader scientific question of how AI
                systems can be informed with social norms and ethics. We also updated the disclaimer
                message to explicitly inform users about the potential unintended offensive,
                problematic, or harmful content from the model.{' '}
            </React.Fragment>
        ),
        date: new Date(2021, 9, 18),
        title: 'Delphi 1.0.1 release',
        id: 'delphi_1.0.1',
    },
    {
        shortDesc: () => (
            <React.Fragment>
                {' '}
                <StyledHashLink to={'/updates#delphi_release'}>Delphi 1.0.0</StyledHashLink> is
                released, along with{' '}
                <a
                    href="https://www.semanticscholar.org/paper/Delphi%3A-Towards-Machine-Ethics-and-Norms-Jiang-Hwang/98eb27ccd9f0875e6e3a350a8a238dc96373a504"
                    target="_blank"
                    rel="noreferrer">
                    our paper
                </a>
                !{' '}
            </React.Fragment>
        ),
        longDesc: () => (
            <React.Fragment>
                {' '}
                Delphi 1.0.0 is released, along with{' '}
                <a
                    href="https://www.semanticscholar.org/paper/Delphi%3A-Towards-Machine-Ethics-and-Norms-Jiang-Hwang/98eb27ccd9f0875e6e3a350a8a238dc96373a504"
                    target="_blank"
                    rel="noreferrer">
                    our paper
                </a>
                !{' '}
            </React.Fragment>
        ),
        date: new Date(2021, 9, 14),
        title: 'Delphi 1.0.0 release',
        id: 'delphi_release',
    },
];
