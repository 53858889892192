import React from 'react';
import styled from 'styled-components';
import { Link } from '@allenai/varnish-react-router';

export const TryExamples: React.SFC<{
    examples: Array<{ label: string; queryString: string }>;
    className?: string;
}> = ({ examples, className }) => (
    <div className={className}>
        <TrySuggestionsLabel>Try one of these examples:</TrySuggestionsLabel>{' '}
        <TrySuggestions>
            <ul>
                {examples.map<React.ReactNode>((ex) => (
                    <li key={ex.label}>
                        <Link to={`/?${ex.queryString}`}>{ex.label}</Link>
                    </li>
                ))}
            </ul>
        </TrySuggestions>
    </div>
);

const TrySuggestionsLabel = styled.label`
    font-weight: ${({ theme }) => theme.typography.font.weight.bold};
    margin-right: ${({ theme }) => theme.spacing.xxs};
    margin-bottom: ${({ theme }) => theme.spacing.xs};
    display: block;
`;

const TrySuggestions = styled.div`
    ul {
        margin-left: ${({ theme }) => theme.spacing.xs};
        list-style-type: none;
        padding: 0;
    }

    max-height: 100px;
    overflow-y: scroll;
    border: ${({ theme }) => `1px solid ${theme.color.N6}`};
    padding: ${({ theme }) => `${theme.spacing.xxs} ${theme.spacing.xs}`};
    border-radius: 4px;
`;
