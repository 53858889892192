import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Alert from 'antd/es/alert';
import Button from 'antd/es/button';
import Input from 'antd/es/input';
import Form from 'antd/es/form';
import List from 'antd/es/list';
import { Link } from '@allenai/varnish-react-router';
import Radio from 'antd/es/radio';
import { RadioChangeEvent } from 'antd/lib/radio';
import { belowOrEqualTo } from '@allenai/varnish/theme/breakpoints';
import {
    StarOutlined,
    FileTextOutlined,
    QuestionCircleOutlined,
    PicRightOutlined,
} from '@ant-design/icons';
import { v4 as uuid } from 'uuid';

import { DelphiClient, Answer, Query, Feedback, AgreementRating } from '../api';
import { TryExamples, DelphiCard, MosaicFooter, NoConsentErrorMessage } from '../components';
import { updates } from '../models';
import { PageProps } from '../App';

/**
 * This type defines the different values that will be accepted from the form's inputs.
 * The names of the keys (Eg. 'action1') must match
 * the HTML input's declared 'name' attribute.
 *
 * Eg:
 * ``` <Form.Item label="Action1:" name="action1"> ... </Form.Item>
 *
 * In this example, we aligned the
 * form's 'name' attributes with the keys of the 'Query' type that is used to query the API.
 */
type FormValue = Query;

export const Home = (props: PageProps) => {
    const exampleParams: { params: string[]; label?: string }[] = [
        {
            params: ['Killing a bear.'],
        },
        {
            params: ['Can I park in a handicap spot if I don’t have a disability?'],
        },
        {
            params: ['Drive your friend to the airport early in the morning.'],
        },
        {
            params: ['Should I run the blender at 3am in the morning when my family is sleeping?'],
        },
        {
            params: ['Ignoring a phone call if the phone call is urgent.'],
        },
        {
            params: ['Helping a friend in need by making a promise that you never follow.'],
        },
        {
            params: ['Feeding your cat using forks.'],
        },
        {
            params: ['Mixing bleach with ammonia to produce chloramine in the lab.'],
        },
        {
            params: ['Stabbing a cheeseburger.'],
        },
        {
            params: ['Men are much better than women.'],
        },
        {
            params: ['Can I torture a cat if it makes me happy?'],
        },
        {
            params: ['Legitimizing racism for the greater good.'],
        },
        {
            params: ['Expressing sexism but in a polite way.'],
        },
        {
            params: ['A cheeseburger stabs a cheeseburger.'],
        },
        {
            params: ['Robbing a bank if you are poor.'],
        },
        {
            params: ['Ignoring my boss’s phone call during my working hours, if I’m in a meeting.'],
        },
        {
            params: ['Ignoring my boss’s phone call during my working hours.'],
        },
        {
            params: ['Ignoring a phone call from your friend during your working hours.'],
        },
        {
            params: ['Ignoring a phone call from your friend.'],
        },
        {
            params: ['Ignoring a phone call from your friend who you just had a fight with.'],
        },
        {
            params: ['Mowing the lawn late at night.'],
        },
        {
            params: ['Mowing the lawn late at night if you live in the middle of nowhere.'],
        },
        {
            params: ['Mowing the lawn when there’s no grass.'],
        },
        {
            params: ['Helping a friend in need if they break the law.'],
        },
        {
            params: ['Cleaning a toilet bowl with a wedding dress.'],
        },
        {
            params: ['Cleaning a toilet bowl with a shirt when you have nothing else to use.'],
        },
        {
            params: ['Cleaning a toilet bowl with a shirt.'],
        },
        {
            params: ['Wearing pajamas to a pajama party.'],
        },
        {
            params: ['Can I wear pajamas to a funeral?'],
        },
        {
            params: ['Should I drive my friend to the airport if I don’t have a license?'],
        },
    ];
    const examples = exampleParams
        .filter((p) => p.params[0])
        .map((p) => {
            return {
                label: p.label || `${p.params[0]}${p.params[1] ? ' vs. ' + p.params[1] : ''}`,
                queryString: new Query(...p.params).toQueryString(),
            };
        });

    const history = useHistory();
    const location = useLocation();
    /**
     * The home page has an Ant-D form, which requires the preservation of state in
     * memory. The links below contain more information about component state
     * and managed forms in React:
     *
     * @see https://reactjs.org/docs/state-and-lifecycle.html
     * @see https://ant.design/components/form/
     * @see https://reactjs.org/docs/forms.html
     *
     * Only use state when necessary, as in-memory representations add a bit of
     * complexity to your UI.
     */
    const [form] = Form.useForm<FormValue>();
    const [isFetchingAnswer, setIsFetchingAnswer] = useState(false);
    const [answer, setAnswer] = useState<Answer | undefined>();
    const [feedback, setFeedback] = useState<Feedback | undefined>();
    const [error, setError] = useState<string | undefined>();
    const [previousAnswers, setPreviousAnswers] = useState<Answer[]>([]);
    const [sessionId] = useState<string>(uuid());
    const [sentFeedback, setSentFeedback] = useState(false);
    const [action1Populated, setAction1Populated] = useState(false);

    // Shuffling algo from https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
    const [shuffledExamples] = useState(
        examples
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)
    );

    const delphiClient = DelphiClient.fromEnv();

    const options = [
        {
            label: 'Yes',
            value: AgreementRating.AGREE,
        },
        {
            label: 'No',
            value: AgreementRating.DISAGREE,
        },
        {
            label: "I don't know",
            value: AgreementRating.DONT_KNOW,
        },
    ];

    /**
     * This is a lifecycle function that's called by React after the component
     * has first been rendered and specifically when the 'location' data changes.
     *
     * You can read more about React component's lifecycle here:
     * @see https://reactjs.org/docs/state-and-lifecycle.html
     */
    useEffect(() => {
        fetchAnswer();
    }, [location]);

    /**
     * Submits a call to the API for a given query as defined in the URL parameters
     * @returns {void}
     */
    const fetchAnswer = async () => {
        const query = Query.fromQueryString(location.search);

        // ensure that we have the expected parameters in the URL
        if (query.isValid()) {
            // See if we can display as specific of an error as possible
            let error;
            if (!props.consented) {
                error = NoConsentErrorMessage;
            } else {
                // remove empty action1 options from the form so that the user knows they're not valid
                const cleanedQuery = new Query(query.action1.trim());
                form.setFieldsValue(cleanedQuery);

                // reset answers and errors before we go fetch new results
                setError(undefined);
                setAnswer(undefined);
                setIsFetchingAnswer(true);

                // Note: validation is done on the backend, so errors will be
                // thrown if there are any invalid inputs
                try {
                    const answer = await delphiClient.predict(query);

                    // When the API returns successfully update the answer
                    setError(undefined);
                    setAnswer(answer);

                    // add current answer to history list
                    if (
                        // but only if its not there already
                        !previousAnswers.some((pa: Answer) =>
                            new Query(pa.query.action1).equals(new Query(answer.query.action1))
                        )
                    ) {
                        setPreviousAnswers([answer, ...previousAnswers]);
                    }
                } catch (err: any) {
                    if (err.response) {
                        // If the check below is true, the API returned
                        // error message that's likely helpful to display
                        if (err.response.data && err.response.data.error) {
                            error = err.response.data.error;
                        } else if (err.response.status === 503 || err.response.status === 502) {
                            error =
                                'Our system is a little overloaded, ' +
                                'please try again in a moment';
                        } else if (err.response.status === 429) {
                            error =
                                "You're submitting requests too quickly. " +
                                'Please try your request again after waiting a minute.';
                        }
                    }

                    // Fallback to a general error message
                    if (!error) {
                        error = 'Something went wrong. Please try again.';
                    }
                    setAnswer(undefined);
                }
                setIsFetchingAnswer(false);
                setFeedback(undefined);
                setSentFeedback(false);
            }
            setError(error);
        }
    };

    /**
     * This handler is invoked when the form is submitted, which occurs when
     * the user clicks the submit button or when the user clicks input while
     * the button and/or a form element is selected.
     *
     * We use this instead of a onClick button on a button as it matches the
     * traditional form experience that end users expect.
     *
     * @see https://reactjs.org/docs/forms.html
     */
    const handleSubmit = (values: FormValue) => {
        // We add the query params to the URL, so that users can link to
        // our demo and share noteworthy cases, edge cases, etc.
        const query = new Query(values.action1);
        // pushing this new URL will automatically trigger a new query (see the 'useEffect' function above)
        history.push(`/?${query.toQueryString()}`);
    };

    const handleAgreementRatingChange = (event: RadioChangeEvent) => {
        const newFeedback = new Feedback(
            event.target.value,
            feedback ? feedback.textFeedback : undefined
        );
        setFeedback(newFeedback);
        delphiClient.sendFeedback(answer, newFeedback, sessionId);
    };

    const handleTextFeedbackChange = (event: any) => {
        const newFeedback = new Feedback(
            feedback ? feedback.agreement : undefined,
            event.target.value
        );
        setFeedback(newFeedback);
    };

    const handleTextFeedbackSubmit = () => {
        delphiClient.sendFeedback(answer, feedback, sessionId);
        setSentFeedback(true);
    };

    const handleAction1Change = (event: any) => {
        setAction1Populated(event.target.value !== '');
    };

    /**
     * The render method defines what's rendered. When writing yours keep in
     * mind that you should try to make it a "pure" function of the component's
     * props and state.  In other words, the rendered output should be expressed
     * as a function of the component properties and state.
     *
     * React executes render whenever a component's properties and/or state is
     * updated. The output is then compared with what's rendered and the
     * required updates are made. This is to ensure that rerenders make as few
     * changes to the document as possible -- which can be an expensive process
     * and lead to slow interfaces.
     */
    return (
        <React.Fragment>
            <OuterContainer>
                <LeftCol>
                    <TopParagraph>
                        Delphi is a research prototype designed to model people’s moral judgments on
                        a variety of everyday situations. This demo shows the abilities and
                        limitations of state-of-the-art models today.
                        <br />
                        <span style={{ fontWeight: 900, fontSize: '16px' }}>
                            {' '}
                            To learn more:
                            <a
                                href="https://api.semanticscholar.org/arXiv:2110.07574"
                                target="_blank"
                                rel="noreferrer">
                                {' '}
                                <FileTextOutlined /> paper{' '}
                            </a>{' '}
                            /
                            <a
                                href="https://medium.com/ai2-blog/towards-machine-ethics-and-norms-d64f2bdde6a3"
                                target="_blank"
                                rel="noreferrer">
                                {' '}
                                <PicRightOutlined /> article{' '}
                            </a>{' '}
                            /
                            {/* <Link to="/">
                                {' '}
                                <ProfileOutlined /> data card{' '}
                            </Link>{' '}
                            / */}
                            <Link to="/faq">
                                {' '}
                                <QuestionCircleOutlined /> FAQ{' '}
                            </Link>{' '}
                        </span>
                        <br />
                    </TopParagraph>
                    <DisclaimerAlert
                        message="Model outputs should not be used for advice for humans, and could be potentially offensive, problematic, or harmful. The model’s output does not necessarily reflect the views and opinions of the authors and their associated affiliations."
                        type="warning"
                    />
                    <Suggestions examples={shuffledExamples} />
                    <FormWrapper
                        layout="vertical"
                        form={form}
                        scrollToFirstError
                        validateMessages={{ required: 'this field is required' }}
                        onFinish={(values) => handleSubmit(values as FormValue)}>
                        <Form.Item
                            label={
                                <span>
                                    Input a <strong>situation</strong> for Delphi to ponder:
                                </span>
                            }
                            name="action1"
                            rules={[{ required: true }]}>
                            <Input.TextArea
                                onChange={handleAction1Change}
                                autoSize={{ minRows: 2, maxRows: 6 }}
                                placeholder="Example: Robbing a bank."
                            />
                        </Form.Item>
                        <Form.Item>
                            {/* Warning: If you choose to remove this Button's 'loading' attribute, you will be responsible for
                            handling multiple asynchronous requests which could lead to inconsistencies. */}
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={isFetchingAnswer}
                                disabled={!action1Populated}>
                                Ponder
                            </Button>
                        </Form.Item>
                        {error && !answer ? (
                            <Alert type="error" message={error || 'Sorry, something went wrong.'} />
                        ) : null}
                    </FormWrapper>

                    {!error && answer ? (
                        <>
                            <DelphiCard answer={answer} />
                            {!sentFeedback ? (
                                <FeedbackContainer>
                                    <div>
                                        <DoYouAgreeSpan> Do you agree with Delphi? </DoYouAgreeSpan>
                                        <Radio.Group
                                            options={options}
                                            onChange={handleAgreementRatingChange}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </div>
                                    {feedback !== undefined && (
                                        <SpacedFeedbackComponentContainer>
                                            <DoYouAgreeSpan>
                                                {' '}
                                                Do you have any feedback to improve this prediction?{' '}
                                            </DoYouAgreeSpan>
                                            <AdditionalFeedbackComponentContainer>
                                                <Input
                                                    placeholder="It would be great if Delphi..."
                                                    onChange={handleTextFeedbackChange}
                                                    value={feedback ? feedback.textFeedback : ''}
                                                />
                                                <Button
                                                    onClick={handleTextFeedbackSubmit}
                                                    disabled={
                                                        !feedback ||
                                                        (feedback && !feedback.textFeedback)
                                                    }
                                                    type="primary">
                                                    {' '}
                                                    Submit{' '}
                                                </Button>
                                            </AdditionalFeedbackComponentContainer>
                                        </SpacedFeedbackComponentContainer>
                                    )}
                                </FeedbackContainer>
                            ) : (
                                <ThankYouMessage>Thank you for your feedback.</ThankYouMessage>
                            )}
                        </>
                    ) : null}
                </LeftCol>
                <RightCol>
                    <NewsContainer>
                        <p> News </p>
                        <NewsList bordered>
                            {updates
                                .sort((a, b) => b.date.getUTCSeconds() - a.date.getUTCSeconds())
                                .map((update, index) => (
                                    <List.Item key={index}>
                                        <b>[{update.date.toDateString().substring(4)}]</b>{' '}
                                        {<update.shortDesc />}
                                    </List.Item>
                                ))}
                        </NewsList>
                    </NewsContainer>
                    <FAQContainer>
                        <FAQ> Frequently Asked Questions </FAQ>
                        <FAQInfo>
                            {' '}
                            For more info, visit our <Link to={'/faq'}> FAQ page. </Link>{' '}
                        </FAQInfo>
                        <FAQQuestion>
                            {' '}
                            Q: What’s the goal of the research behind Delphi?{' '}
                        </FAQQuestion>
                        <FAQAnswer>
                            {' '}
                            <span> A: </span> Extreme-scale neural networks learned from raw
                            internet data are ever more powerful than we anticipated, but to what
                            extent can they learn to behave in an ethically-informed and
                            socially-aware manner? Delphi demonstrates both the promises and the
                            limitations of language-based neural models when taught with ethical
                            judgments made by people.{' '}
                        </FAQAnswer>
                        <FAQQuestion>
                            {' '}
                            Q: How robust is Delphi against race- and gender-related statements?{' '}
                        </FAQQuestion>
                        <FAQAnswer>
                            {' '}
                            <span> A: </span>
                            Delphi 1.0.4 demonstrates 97.9% accuracy on race-related and 99.3% on
                            gender-related statements. After its initial launch, we enhanced Delphi
                            1.0.0’s guards against statements about racism and sexism, which used to
                            show 91.2% and 97.3% accuracy.{' '}
                        </FAQAnswer>
                        <FAQQuestion>
                            {' '}
                            Q: Is it true that Delphi is learning moral judgments from Reddit?{' '}
                        </FAQQuestion>
                        <FAQAnswer>
                            {' '}
                            <span> A: </span> No. Delphi is learning moral judgments from people who
                            are carefully qualified on MTurk. Only the situations used in questions
                            are harvested from Reddit, as it is a great source of ethically
                            questionable situations.{' '}
                        </FAQAnswer>
                        <Button icon={<StarOutlined />} href="https://allenai.org/demos">
                            Try other AI demos from AI2
                        </Button>
                    </FAQContainer>
                </RightCol>

                <Bottom>
                    {answer && previousAnswers.length > 1 ? (
                        <>
                            <h5>Previous Responses</h5>
                            <PreviousCardArea>
                                {previousAnswers
                                    .filter(
                                        // filter out current query response
                                        (pa) =>
                                            !new Query(pa.query.action1).equals(
                                                new Query(answer.query.action1)
                                            )
                                    )
                                    .map((pa) => {
                                        const href = `/?${new Query(
                                            pa.query.action1
                                        ).toQueryString()}`;
                                        return (
                                            <PreviousCard key={href}>
                                                <DelphiCard answer={pa} isPrevious={true} />
                                                <a href={href}>{`${pa.query.action1}`}</a>
                                            </PreviousCard>
                                        );
                                    })}
                            </PreviousCardArea>
                        </>
                    ) : null}

                    <MosaicFooter />
                </Bottom>
            </OuterContainer>
        </React.Fragment>
    );
};

/**
 * The definition below creates a component that we can use in the render
 * function above that have extended / customized CSS attached to them.
 * Learn more about styled components:
 * @see https://www.styled-components.com/
 *
 *
 * CSS is used to modify the display of HTML elements. If you're not familiar
 * with it here's quick introduction:
 * @see https://developer.mozilla.org/en-US/docs/Web/CSS
 */

const OuterContainer = styled.div`
    display: grid;
    grid-column-gap: ${({ theme }) => theme.spacing.xl3};
    grid-row-gap: ${({ theme }) => theme.spacing.lg};
    grid-template:
        'top top'
        'leftCol rightCol'
        'bottom bottom' / 2fr 1fr;
    p {
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    }

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        grid-template:
            'top'
            'leftCol'
            'rightCol'
            'bottom';
    }
`;

const LeftCol = styled.div`
    grid-area: leftCol;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        max-width: unset;
    }
`;

const RightCol = styled.div`
    grid-area: rightCol;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        max-width: unset;
    }
`;

const FAQContainer = styled.div`
    border-radius: ${({ theme }) => theme.shape.borderRadius.lg};
    padding: ${({ theme }) => theme.spacing.md};
    margin-top: ${({ theme }) => theme.spacing.xl};
    background-color: ${({ theme }) => theme.color.N2};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        margin-bottom: ${({ theme }) => theme.spacing.xl};
        margin-top: 0;
    }

    &&& {
        .ant-btn {
            margin-top: ${({ theme }) => theme.spacing.lg};
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
    }
`;

const Bottom = styled.div`
    grid-area: bottom;
    margin-top: ${({ theme }) => theme.spacing.xl};

    h5 {
        text-transform: none;
    }
`;

const FormWrapper = styled(Form)``;

const DisclaimerAlert = styled(Alert)`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    }
`;

const TopParagraph = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.lg};
        font-size: 1.25em;
    }
`;

const PreviousCard = styled.div`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.lg};
    }
`;

const PreviousCardArea = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: ${({ theme }) => theme.spacing.xl};

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        grid-template-columns: 1fr;
        max-width: ${({ theme }) => theme.breakpoints.sm};
    }
`;

const Suggestions = styled(TryExamples)`
    margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const DoYouAgreeSpan = styled.span`
    margin-right: ${({ theme }) => theme.spacing.sm};
`;

const FeedbackContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing.lg};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
    background-color: ${({ theme }) => theme.color.N2};
    border-radius: 20px;
    border: ${({ theme }) => `1px dashed ${theme.color.N6}`};
    padding: ${({ theme }) => theme.spacing.lg};
`;

const SpacedFeedbackComponentContainer = styled.div`
    margin-top: ${({ theme }) => theme.spacing.md};
`;

const AdditionalFeedbackComponentContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr auto;
    grid-gap: ${({ theme }) => theme.spacing.sm};
`;

const ThankYouMessage = styled.div`
    margin-top: ${({ theme }) => theme.spacing.sm};
    margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

const FAQ = styled.span`
    &&& {
        font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        margin-bottom: ${({ theme }) => theme.spacing.md};
        display: block;
        font-size: 1.25em;
    }
`;

const FAQInfo = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.md};
    }
`;

const FAQQuestion = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.xxs};
        margin-left: ${({ theme }) => theme.spacing.sm};
        font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        color: ${({ theme }) => theme.color.O8};
    }
`;

const FAQAnswer = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.sm};
        margin-left: ${({ theme }) => theme.spacing.sm};
        font-size: 0.9em;

        span {
            font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        }
    }
`;

const NewsContainer = styled.div`
    > p {
        font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        font-size: 1.25em;
        margin-bottom: ${({ theme }) => theme.spacing.md};
    }
`;

const NewsList = styled(List)`
    max-height: 185px;
    overflow-y: scroll;

    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    }

    &&& {
        li {
            padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.md}`};
            font-size: 0.9em;
        }
    }
`;
