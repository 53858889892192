/**
 * Representation of a query, which consists of a action1.
 */
export class Query {
    constructor(readonly action1: string = '') {}

    // defines how the URL will identify the parameters we want to store
    private static readonly action1URLKey = 'a1';

    /**
     * Returns whether the current query has any populated values.
     * This catches scenarios where rogue url strings are provided /?random=badValue
     *
     * @return {boolean}
     */
    isValid() {
        return this.action1;
    }

    /**
     * Serializes the query to a URL appropriate representation.
     *
     * @returns {string}
     */
    toQueryString(): string {
        const params = new URLSearchParams();
        params.set(Query.action1URLKey, this.action1);
        return params.toString();
    }

    /**
     * Test equality of the query.
     *
     * @param {Query} other
     *
     * @returns {boolean}
     */
    equals(other: Query): boolean {
        return this.toQueryString() === other.toQueryString();
    }

    /**
     * Factory that returns a Query instance based upon the provided URL search parameters.
     *
     * @param {string} searchParams
     *
     * @returns {Query}
     */
    public static fromQueryString(searchParams: string): Query {
        const params = new URLSearchParams(searchParams);
        return new Query(params.get(Query.action1URLKey) ?? '');
    }
}
