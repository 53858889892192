import React from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';
import { Link } from '@allenai/varnish-react-router';

import { MosaicFooter, NoConsentAlert } from '../components';
import { updates } from '../models';
import { PageProps } from '../App';

export const Updates = (props: PageProps) => {
    const location = useLocation();

    return (
        <React.Fragment>
            <div>
                <h3> Updates </h3>
                <p>
                    {' '}
                    Based on community feedback, we have updated our <Link to="/">demo</Link>. This
                    page shows a history of the changes to this demo. If you have any other
                    questions about this research, please contact{' '}
                    <a href="mailto:delphi@allenai.org">delphi@allenai.org</a>.
                </p>
                {props.consented ? (
                    <>
                        {updates
                            .sort((a, b) => b.date.getUTCSeconds() - a.date.getUTCSeconds())
                            .map((update, index) => {
                                return (
                                    <div key={index} id={update.id}>
                                        <Question>
                                            {' '}
                                            [{update.date.toDateString().substring(4)}]{' '}
                                            {update.title}{' '}
                                        </Question>
                                        <Answer>
                                            {' '}
                                            <update.longDesc hash={location.hash} />{' '}
                                        </Answer>
                                    </div>
                                );
                            })}
                    </>
                ) : (
                    <NoConsentAlert />
                )}
            </div>
            <Bottom>
                <MosaicFooter />
            </Bottom>
        </React.Fragment>
    );
};

const Bottom = styled.div`
    grid-area: bottom;
    margin-top: ${({ theme }) => theme.spacing.xl};

    h5 {
        text-transform: none;
    }
`;

const Question = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.xxs};
        margin-left: ${({ theme }) => theme.spacing.sm};
        font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        color: ${({ theme }) => theme.color.O8};
        font-size: 1.25em;
    }
`;

const Answer = styled.p`
    &&& {
        margin-bottom: ${({ theme }) => theme.spacing.lg};
        margin-left: ${({ theme }) => theme.spacing.sm};

        span {
            font-weight: ${({ theme }) => theme.typography.font.weight.bold};
        }
    }
`;
