import axios from 'axios';

import { Answer } from './Answer';
import { Feedback } from './Feedback';
import { Query } from './Query';

export class DelphiClient {
    constructor(readonly origin: string) {}

    predict(q: Query) {
        const url = `${this.origin}/predict`;
        const config = { params: { action1: q.action1 } };
        return axios.get<Answer>(url, config).then((r) => r.data);
    }

    sendFeedback(answer: Answer | undefined, feedback: Feedback | undefined, sessionId: string) {
        const payload = {
            answer: answer,
            feedback: Feedback.FeedbackToPayload(feedback),
            sessionId: sessionId,
        };
        const url = `${this.origin}/feedback`;
        return axios.post(url, payload);
    }

    static fromEnv() {
        if (!process.env.DELPHI_API_ORIGIN) {
            throw new Error('DELPI_API_ORIGIN must be set and non-empty');
        }
        return new DelphiClient(process.env.DELPHI_API_ORIGIN);
    }
}
